import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";

function App() {
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [amount, setAmount] = useState("");
  const [fuelPrice, setFuelPrice] = useState("");
  const [vehicle, setVehicle] = useState("Hyundai i40");
  const [mileage, setMileage] = useState("");
  const [station, setStation] = useState("");
  const [gasolineType, setGasolineType] = useState("95");
  const [sumAmount, setSumAmount] = useState(0);
  const [error, setError] = useState(null);
  const [lastAvgConsumption, setlastAvgConsumption] = useState(null);

  useEffect(() => {
    axios
      .get("http://fazekasds218j.synology.me:3001/api/getLastAvgConsumption")
      .then((response) => {
        setlastAvgConsumption(response.data);
        setError(null);
      })
      .catch((error) => {
        console.error(error);
        setError("Failed to fetch startup SQL query data.");
      });
  }, [sumAmount]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const calculatedSumAmount = parseFloat(amount) * parseFloat(fuelPrice);
    setSumAmount(calculatedSumAmount);

    const formData = {
      date,
      amount,
      fuelPrice,
      vehicle,
      mileage,
      station,
      gasolineType,
    };

    axios
      .post("http://fazekasds218j.synology.me:3001/api/saveFuelData", formData)
      .then((response) => {
        console.log(response.data);
        resetForm();
        setError(null);
      })
      .catch((error) => {
        console.error(error);
        setError("Failed to save fuel data. Please try again.");
      });
  };

  const resetForm = () => {
    setDate(new Date().toISOString().split("T")[0]);
    setAmount("");
    setFuelPrice("");
    setVehicle("Hyundai i40");
    setMileage("");
    setStation("");
    setGasolineType("95");
    setSumAmount(0);
  };

  return (
    <div className="container">
      <h1>Fuel Tracker</h1>
      <form className="fuel-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="date">Date</label>
          <input
            type="date"
            id="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="amount">Amount (in liters)</label>
          <input
            type="number"
            id="amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="fuelPrice">Fuel Price (in Huf)</label>
          <input
            type="number"
            id="fuelPrice"
            value={fuelPrice}
            onChange={(e) => setFuelPrice(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="vehicle">Vehicle</label>
          <select
            id="vehicle"
            value={vehicle}
            onChange={(e) => setVehicle(e.target.value)}
          >
            <option value="Hyundai i40">Hyundai i40</option>
            <option value="Yamaha X-Max 300">Yamaha X-Max 300</option>
            <option value="Kawasaki ZX9R">Kawasaki ZX9R</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="mileage">Mileage</label>
          <input
            type="number"
            id="mileage"
            value={mileage}
            onChange={(e) => setMileage(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="station">Tank Station</label>
          <input
            type="text"
            id="station"
            value={station}
            onChange={(e) => setStation(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="gasolineType">Gasoline Type</label>
          <select
            id="gasolineType"
            value={gasolineType}
            onChange={(e) => setGasolineType(e.target.value)}
          >
            <option value="95">95</option>
            <option value="100">100</option>
            <option value="diesel">Diesel</option>
          </select>
        </div>
        <button type="submit">Submit</button>
        {lastAvgConsumption && (
          <label htmlFor="lastAvgConsumption">
            Last average consumption:{" "}
            {lastAvgConsumption[0].average_consumption}
          </label>
        )}{" "}
      </form>
    </div>
  );
}

export default App;
